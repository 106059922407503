<template>
  <div style="text-align: center;height:60px ;padding: 20px;">
    <el-button type="success" @click="getcertfind">证书查询</el-button>
    <el-button @click="buildCert">重新生成</el-button>
    <el-button @click="download">下载证书</el-button>
    <el-button @click="download2">下载证书2</el-button>
  </div>
  <el-image v-if="imgurl" :src="imgurl" fit="contain" style="width: 50%;height: 35vw;"/>
  <el-image v-if="img2url" :src="img2url" fit="contain" style="width: 50%;height: 35vw;"/>
</template>

<script>
    export default {
      data() {
        return {
          imgurl: '',
          img2url: '',
          citizenId: '',
          name: ''
        }
      },
      mounted: function () {
        this.$net.certUrl().then(({ data }) => {
          this.imgurl=data.certUrl
          this.img2url=data.cert2Url
          this.name = data.name
          this.citizenId = data.citizenId
        });
      },
      methods:{
        download(){
          this.downloadFile(this.imgurl,'证书')
        },
        download2(){
          this.downloadFile(this.img2url,'证书2')
        },
        downloadFile(url,name){
          var x=new XMLHttpRequest();
          x.open("GET", url, true);
          x.responseType = 'blob';
          x.onload=function(){
              var url = window.URL.createObjectURL(x.response)
              var a = document.createElement('a');
              a.href = url
              a.download = name+'.jpg'
              a.click()
          }
          x.send();
        },
        buildCert(){
          this.$net.buildCert().then(({ data }) => {
            if (data.code != 0) {
              this.$alert(data.msg)
              return
            }
            this.imgurl=data.certUrl
            this.img2url=data.cert2Url
          });
        },
        getcertfind(){
          //本地测试
          // window.open(`http://192.168.1.103:8088/cert/getfindcert?name=${this.name}&citizenId=${this.citizenId}`, '_blank')
          //生产环境
          window.open(`http://36.134.67.33:9085/cert/getfindcert?name=${this.name}&citizenId=${this.citizenId}`, '_blank')
        }
      }
    }
  </script>
